//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from '../../../../common/ResponsiveImage';
import AnchorsList from './components/AnchorsList/Index';
import SizeTable from './components/SizeTable/SizeTable.vue';
import SizeTableChange from './components/SizeTable/SizeTableChange.vue';
import SizeCarousel from '~/components/popups/SizeTable/components/SizeCarousel/index';

export default {
    name: 'SizeTrack',
    components: {
        SizeCarousel,
        SizeTableChange,
        SizeTable,
        AnchorsList,
        ResponsiveImage
    },
    data: function() {
        return {
            bootsMaterialImage: StaticImages[`sizeTableBootsMaterialBlock_${ this.$i18n.locale }`],
            bootsComfortableImage: StaticImages[`sizeTableBootsComfortableBlock_${ this.$i18n.locale }`],
            M: {
                sizeTable: {
                    head: {
                        centimeter: [this.$t('sizeTable.sizeMTrack.table.headers.centimeter.title'), 'XS 1 - 2', 'S 3 - 4', 'M 5 - 6', 'L 7 - 8', 'XL 9 - 10', '2XL 11 - 12', '3XL 13 - 14']
                    },
                    body: {
                        centimeter: [
                            [this.$t('sizeTable.sizeMTrack.table.body.centimeter.subtitle1'), '82 - 87 см', '88 - 94 см', '95 - 102 см', '103 - 111 см', '112 - 121 см', '122 - 132 см', '133 - 144 см'],
                            [this.$t('sizeTable.sizeMTrack.table.body.centimeter.subtitle2'), '71 - 75 см', '76 - 82 см', '83 - 90 см', '91 - 99 см', '100 - 109 см', '110 - 121 см', '122 - 134 см'],
                            [this.$t('sizeTable.sizeMTrack.table.body.centimeter.subtitle3'), '82 - 86 см', '87 - 93 см', '94 - 101 см', '102 - 110 см', '111 - 119 см', '120 - 128 см', '120 - 128 см'],
                            [this.$t('sizeTable.sizeMTrack.table.body.centimeter.subtitle4'), '81 см', '81.5 см', '82 см', '82.5 см', '83 см', '82.5 см', '82 см'],
                        ]
                    }
                },
                sizeChangeTable: {
                    head: {
                        centimeter: ['UA', 'XS / 40 - 42', 'S / 44 - 46', 'M / 48 - 50', 'L / 52 - 54', 'XL / 56 - 58', '2XL / 60 - 62', '3XL / 64 - 66']
                    },
                    body: {
                        centimeter: [
                            ['US', 'XS / 30/32 | 32/34', 'S / 34/36 | 36/38', 'M / 38/40 | 40/42', 'L / 42/44 | 44/46', 'XL / 46/48 | 48/50', '2XL / 50/52 | 52/54', '3XL / 54/56 | 56/58'],
                            ['UK', 'XS / 30/32 | 32/34', 'S / 34/36 | 36/38', 'M / 38/40 | 40/42', 'L / 42/44 | 44/46', 'XL / 46/48 | 48/50', '2XL / 50/52 | 52/54', '3XL / 54/56 | 56/58'],
                            ['FR', 'XS / 40 - 42', 'S / 44 - 46', 'M / 48 - 50', 'L / 52 - 54', 'XL / 56 - 58', '2XL / 60 - 62', '3XL / 64 - 66'],
                            ['IT', 'XS / 40 - 42', 'S / 44 - 46', 'M / 48 - 50', 'L / 52 - 54', 'XL / 56 - 58', '2XL / 60 - 62', '3XL / 64 - 66'],
                        ]
                    }
                },

            },
            W: {
                sizeTable: {
                    head: {
                        centimeter: [this.$t('sizeTable.sizeWTrack.table.headers.centimeter.title'), 'XXS 0 - 2', 'XS 4 - 6', 'S 8 - 10', 'M 12 - 14', 'L 16 - 18', 'XL 20 - 22', '2XL 24 - 26']
                    },
                    body: {
                        centimeter: [
                            [this.$t('sizeTable.sizeWTrack.table.body.centimeter.subtitle1'), '73 - 76 см', '77 - 82 см', '83 - 88 см', '89 - 94 см', '95 - 101 см', '102 - 109 см', '110 - 118 см'],
                            [this.$t('sizeTable.sizeWTrack.table.body.centimeter.subtitle2'), '57 - 60 см', '61 - 66 см', '67 - 72 см', '73 - 78 см', '79 - 85 см', '86 - 94 см', '94 - 104 см'],
                            [this.$t('sizeTable.sizeWTrack.table.body.centimeter.subtitle3'), '82 - 85 см', '86 - 91 см', '92 - 97 см', '98 - 103 см', '104 - 110 см', '111 - 117 см', '118 - 125 см'],
                            [this.$t('sizeTable.sizeWTrack.table.body.centimeter.subtitle4'), '77.5 см', '78 см', '78.5 см', '79 см', '79.5 см', '80 см', '80.5 см'],
                        ]
                    }
                },
                sizeChangeTable: {
                    head: {
                        centimeter: ['UA', 'XXS / 26 - 28', 'XS / 30 - 32', 'S / 34 - 36', 'M / 38 - 40', 'L / 42 - 44', 'XL / 46 - 48', '2XL / 50 - 52'],
                    },
                    body: {
                        centimeter: [
                            ['UK', 'XXS / 0 - 2', 'XS / 4 - 6', 'S / 8 - 10', 'M / 12 - 14', 'L / 16 - 18', '2XL / 56 - 58', '2XL / 24 - 26'],
                            ['US', 'XXS / --', 'XS / 0 - 2', 'S / 4 - 6', 'M / 8 - 10', 'L / 12 - 14', 'XL / 16 - 18', '2XL / 20 - 22'],
                            ['FR', 'XXS / 28 - 30', 'XS / 0 - 2', 'S / 36 - 38', 'M / 40 - 42', 'L / 44 - 46', 'XL / 48 - 50', '2XL / 52 - 54'],
                            ['IT', 'XXS / 32 - 34', 'XS / 36 - 38', 'S / 40 - 42', 'M / 44 - 46', 'L / 48 - 50', 'XL / 52 - 54', '2XL / 56 - 58'],
                        ]
                    }
                },

            },
        };
    },
    props: {
        contentTexts: {
            type: Object
        },
        contentType: {
            type: String,
            default: 'M'
        },
    },
    computed: {
        imageSrc() {
            return this.isWomenSwimsuits ? this.swimsuitsImage : this.clothesImage;
        },
        l() {
            return this.$i18n.locale;
        },
        computedTablesType() {
            return this[this.contentType];
        }
    },
    methods: {
        scrollTo(el) {
            return {
                el: el,
                container: '.v-popup__container__body',
                duration: 300,
                lazy: false,
                easing: 'linear',
                cancelable: true,
                x: false,
                y: true
            };
        },
        imageConfig(url, title = '', alt = '') {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            };
        },
    },
};
