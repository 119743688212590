//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "AnchorsList",
    props: {
        array: {
            type: Array,
            required: true
        },
        scrollTo: {
            type: Function,
            required: true
        },
        className: {
            type: String,
            default: ''
        }
    },
}
