//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from '~/components/common/ResponsiveImage.vue';

export default {
    name: 'SizeCarousel',
    components: { ResponsiveImage },
    data: function() {
        return {
            activeSlide: 0,
            moveNow: false,
        };
    },
    props: {
        sizeArray: {
            required: true,
            default: [],
        }
    },
    methods: {
        imageConfig(url, title = '', alt = '') {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            };
        },
        selectItem(id, dragNow = false) {
            if (!this.moveNow || dragNow) {
                this.activeSlide = +id - 1;
                this.$refs['carouselList'].style.transform = `translate3d(-${ 244 * this.activeSlide }px, 0px, 0px)`;
            }
        },
        swipeRight() {
            let index = this.activeSlide - 1;
            this.activeSlide = index < 0 ? 0 : index;
            this.selectItem(this.activeSlide + 1, true);
        },
        swipeLeft() {
            let index = this.activeSlide + 1;
            this.activeSlide = index > 3 ? 3 : index;
            this.selectItem(this.activeSlide + 1, true);
        },
        startHandler() {
            this.moveNow = true;
        },
        endHandler() {
            setTimeout(() => {
                this.moveNow = false;
            }, 250);
        },
    },
};
